// @ts-nocheck
import React from 'react';
import { useIntl, FormattedHTMLMessage, injectIntl } from 'gatsby-plugin-intl';
import { Row, Col } from 'react-bootstrap';
import 'animate.css/animate.min.css';
import styled from 'styled-components';

import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Title from '../../components/Title';
import heroImage from '../../images/vancouver-background.jpg';
import Footer from '../../components/Footer';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h2`
  color: #074166;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  margin: 1em 0em 1em 0em;
`;

const ParagraphContainer = styled.div`
  max-width: 100%;
  margin: 1em 1em 0em 1em;

  @media (min-width: 992px) {
    width: 95%;
    margin: 0em auto;
  }
`;

const Callout = styled.a`
  &&.btn {
    margin: 0em auto 1em auto;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 0.7em 1em;
    align-items: center;
    display: flex;
    animation-delay: ${(props) => `${props.delay}s`};
  }
  &&.btn-primary {
    background: #185475;
    border-color: #185475;
  }
  &&.btn-secondary {
    background: #e9e9e9;
    border-color: #e9e9e9;
    color: black;
  }
  && svg {
    float: right;
    margin-left: 0.5em;
  }
  @media (min-width: 992px) {
    &&.btn-primary {
      margin: 0em 0em 1em auto;
    }
    &&.btn-secondary {
      // margin: 0em auto 1em 0em;
      margin: auto;
    }
  }
`;

const VancouverPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title="Vancouver" />
      <Wrapper className="container-fluid">
        <Title
          imageUrl={heroImage}
          position="center"
          text={intl.formatMessage({
            id: 'locations.canada.vancouver.title',
          })}
        />
        <Heading>
          {intl.formatMessage({
            id: 'locations.canada.vancouver.welcome_text',
          })}
        </Heading>
        <ParagraphContainer>
          <FormattedHTMLMessage id="locations.canada.vancouver.text" />
        </ParagraphContainer>
        <Row>
          {/* <Col lg={6} className="d-flex">
            <Callout href="/apply" className="btn btn-primary">
              Apply now <i className="fas fa-chevron-right" />
            </Callout>
          </Col> */}
          <Col lg={12} className="d-flex">
            <Callout href="/contact-us" className="btn btn-secondary">
              {intl.formatMessage({ id: 'locations.contact_text' })}{' '}
              <i className="fas fa-chevron-right" />
            </Callout>
          </Col>
        </Row>
        <Footer />
      </Wrapper>
    </Layout>
  );
};

export default injectIntl(VancouverPage);
